import { useState } from 'react';
import {
  Text,
  Box,
  Columns,
  Column,
  TextField,
  Button,
  Strong,
  Stack,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';

import translations from './.vocab';

import { Section } from '../../common/section/Section';
import type { JobsModel } from '../../../';
import * as styles from './jobSearchSection.css';

interface Props {
  mode: 'view' | 'edit';
  model?: JobsModel;
}

export function JobSearchSection({ mode, model }: Props) {
  const { t } = useTranslations(translations);
  const [searchField, setSearchField] = useState('');

  const totalJobs = model?.totalJobs || 0;
  const companyName = model?.companyName || '';
  const foreignCompanyId = model?.foreignCompanyId || '';

  const jobSearchPrefix = searchField ? `${searchField}-` : '';
  const jobSearchUrl = `/${jobSearchPrefix}jobs?cid=company-profile&ref=company-profile&companyid=${foreignCompanyId}`;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      window.location.assign(jobSearchUrl);
    }
  };

  return (
    <Section mode={mode}>
      <Stack space="large">
        <Box className={styles.jobSearch} onKeyDown={handleKeyDown}>
          <Columns space="small" collapseBelow="tablet" alignY="bottom">
            <Column>
              <TextField
                id="search-field"
                label={t('Refine by job type')}
                onChange={(event) => {
                  setSearchField(event.currentTarget.value);
                }}
                value={searchField}
                onClear={() => setSearchField('')}
                placeholder={t('eg: Baker')}
              />
            </Column>
            <Column width="content">
              <Button
                variant="ghost"
                onClick={() => {
                  window.location.assign(jobSearchUrl);
                }}
              >
                {t('Show jobs')}
              </Button>
            </Column>
          </Columns>
        </Box>
        <Text size="small">
          {totalJobs &&
            t('{totalJobs} jobs in {companyName}', {
              totalJobs: totalJobs.toString(),
              strong: (children) => <Strong>{children}</Strong>,
              companyName,
            })}
        </Text>
      </Stack>
    </Section>
  );
}
