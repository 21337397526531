import type {
  GetCompaniesResult,
  GetCompanyResult,
  GetCompanyReviewsJobTitlesResult,
  LegacyCompanyProfile,
  LegacyCompanyReviewsSummaryResult,
  LegacySearchCompaniesResult,
} from './types';
import { getHeaders, creatRestClient, type Logger } from '@seek/libs-shared';
import type { AppConfig } from '../../config';

export interface LegacyCompanyProfileServiceOptions {
  companyProfilesApiBaseUrl: string;
  config: AppConfig;
  logger: Logger;
}

export const createLegacyCompanyProfileService = ({
  companyProfilesApiBaseUrl,
  config,
  logger,
}: LegacyCompanyProfileServiceOptions) => {
  const commonHeaders = getHeaders(config)();
  // omit 'x-real-ip' header from headers
  delete commonHeaders['x-real-ip'];

  const { get } = creatRestClient(companyProfilesApiBaseUrl, logger, {
    ...commonHeaders,

    'x-country-iso': config.country,
  });

  const getCompanies = async (options: {
    page?: number;
    per_page?: number;
    company_name?: string;
    foreign_company_id?: string;
    sort?: string;
    advertiser_id?: string;
  }): Promise<GetCompaniesResult> => {
    const response = await get<GetCompaniesResult>(
      `/companies?${new URLSearchParams(options as Record<string, string>)}`,
    );

    return response?.data;
  };

  const getLegacyCompanyProfile = async (companyId: string) => {
    const response = await get<LegacyCompanyProfile>(
      `/companies/${companyId}/profile`,
    );

    return response?.data;
  };

  const getLegacyCompanyProfilePreview = async (companyId: string) => {
    const response = await get<LegacyCompanyProfile>(
      `/companies/${companyId}/profile/preview`,
    );

    return response?.data;
  };

  const getCompany = async (companyId: string): Promise<GetCompanyResult> => {
    const response = await get<GetCompanyResult>(`/companies/${companyId}`);

    return response.data;
  };

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string> => {
    const response = await get<LegacyCompanyProfile>(
      `/companies/${companyId}/profile`,
    );

    return response?.data?.foreign_company_id;
  };

  const getCompanyReviewsSummary = async (
    companyId: string,
  ): Promise<LegacyCompanyReviewsSummaryResult> => {
    const response = await get<LegacyCompanyReviewsSummaryResult>(
      `/companies/${companyId}/company-reviews/summary`,
    );

    return response?.data;
  };

  const searchCompanies = async ({
    searchTerm,
  }: {
    searchTerm: string;
  }): Promise<LegacySearchCompaniesResult> => {
    const queryParam = new URLSearchParams({
      company_name: searchTerm,
      page: '1',
      per_page: '6',
      output_format: 'search',
    });

    const response = await get<LegacySearchCompaniesResult>(
      `/companies?${queryParam.toString()}`,
    );
    return response?.data;
  };

  const getCompanyReviewsJobTitles = async (
    companyId: string,
  ): Promise<GetCompanyReviewsJobTitlesResult> => {
    const response = await get<GetCompanyReviewsJobTitlesResult>(
      `/companies/${companyId}/company-reviews/job-titles`,
    );

    return response?.data;
  };

  const getReviewsFeed = async ({
    companyId,
    country,
  }: {
    companyId: number;
    country: string;
  }) =>
    await get(`/companies/${companyId}/reviews/feed`, {
      headers: {
        'x-country-iso': country,
      },
    });

  return {
    getCompanies,
    getCompany,
    getCompanyProfileForeignId,
    getCompanyReviewsJobTitles,
    getCompanyReviewsSummary,
    getLegacyCompanyProfile,
    getLegacyCompanyProfilePreview,
    getReviewsFeed,
    searchCompanies,
  };
};
