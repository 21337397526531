import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';
import type { AboutModel } from './aboutModel';
import type { CompanyProfile } from '@seek/libs-shared';
import { CompaniesListModel } from '../../models/companiesListModel';

const mapAboutModel = ({
  companyProfile,
  randomCompaniesInThisIndustry,
}: {
  companyProfile: CompanyProfile;
  randomCompaniesInThisIndustry: CompaniesListModel;
}): AboutModel => ({
  name: companyProfile.name,
  overview: companyProfile.overview,
  reviewSummary: {
    ratingBreakdown: companyProfile.ratingBreakdown,
    salaryRating: companyProfile.salaryRating,
    employeeRecommendationRating: companyProfile.employeeRecommendationRating,
    companyReviewSummaryBreakdown: companyProfile.companyReviewSummaryBreakdown,
    ...(companyProfile.overallRating
      ? {
          overallRating: {
            average: companyProfile.overallRating?.average,
            count: companyProfile.overallRating?.count,
          },
        }
      : {}),
  },
  featured: companyProfile.featured,
  awards: companyProfile.awards?.map((award) => ({
    ...award,
    name: award.name || '',
  })),
  missionStatement: companyProfile.missionStatement,
  gallery: companyProfile.gallery,
  branding: companyProfile.branding,
  randomCompaniesInThisIndustry,
});

const extractCompanyIdFromSlug = (input?: string): string | undefined => {
  if (!input) {
    return undefined;
  }
  const parts = input.split('-');
  return parts[parts.length - 1];
};

export const aboutLoader =
  ({ services }: RouteProps): LoaderFunction =>
  async ({ params, request }): Promise<AboutModel> => {
    const companyId = extractCompanyIdFromSlug(params.companySlug);

    if (companyId === undefined) {
      throw new Error('Could not find companyId from params', params);
    }
    const url = new URL(request.url);
    const isDraft = url.searchParams.get('draft');

    const companyProfile =
      await services.companyProfileService.getCompanyProfile(
        companyId,
        Boolean(isDraft),
      );

    const formattedDateInUTC = new Date().toISOString().split('T')[0];

    const randomCompaniesInThisIndustry =
      await services.companyProfileService.getGroupedCompanies({
        groupBy: {
          group: 'INDUSTRY',
          value: [companyProfile.overview?.industry || ''],
        },
        perPage: 13,
        excludedCompanyIds: [companyId],
        randomSeed: formattedDateInUTC,
      });

    const about = mapAboutModel({
      companyProfile,
      randomCompaniesInThisIndustry,
    });

    return {
      ...about,
      metadata: {
        legacy:
          await services.legacyCompanyProfileService.getCompanyReviewsJobTitles(
            companyId,
          ),
      },
    };
  };
