import { Logger } from '../logging/logger';
import { v4 as uuid } from 'uuid';

export interface TypedResponse<TResponse> extends Response {
  data: TResponse;
  status: number;
  statusText: string;
  contentType: string;
}

export const creatRestClient = (
  baseUrl: string,
  logger: Logger,
  headers?: RequestInit['headers'],
) => {
  const send = async <TRequest, TResponse>(
    path: string,
    method: string,
    request?: TRequest | undefined,
    options?: Partial<RequestInit>,
  ) => {
    const requestUrl = `${baseUrl}${path}`;

    const response = await fetch(requestUrl, {
      ...options,
      method,
      mode: typeof window === 'undefined' ? 'no-cors' : 'cors',
      headers: {
        ...headers,
        'x-request-id': uuid(),
        ...options?.headers,
      },
      body: request ? JSON.stringify(request) : undefined,
    });

    let bodyContent: TResponse | undefined;
    try {
      const body = await response.text();
      if (body) {
        try {
          bodyContent = JSON.parse(body) as TResponse;
        } catch (e) {
          bodyContent = body as TResponse;
        }
      }
    } catch (error) {
      logger.error({ requestUrl, error }, 'Error in Requesting External API');
    }

    const fetchResonse: TypedResponse<TResponse> = {
      ...response,
      data: bodyContent as TResponse,
      status: response.status,
      contentType: response.headers.get('content-type') ?? '',
    };

    return fetchResonse;
  };

  const get = async <TResponse = void>(path: string, options?: RequestInit) =>
    send<undefined, TResponse>(path, 'GET', undefined, options);

  const post = async <TRequest, TResponse = void>(
    path: string,
    request: TRequest,
    options?: RequestInit,
  ) => send<TRequest, TResponse>(path, 'POST', request, options);

  const patch = async <TRequest, TResponse = void>(
    path: string,
    request: TRequest,
    options?: RequestInit,
  ) => send<TRequest, TResponse>(path, 'PATCH', request, options);

  const $delete = async <TRequest, TResponse = void>(
    path: string,
    request: TRequest,
    options?: RequestInit,
  ) => send<TRequest, TResponse>(path, 'DELETE', request, options);

  return {
    get,
    post,
    patch,
    $delete,
  };
};
