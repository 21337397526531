import { ReviewRowModel } from '@seek/libs-shared';
import { SeoStructuredData } from './SeoStructuredData';
import { AboutModel } from '../../pages/about/aboutModel';
import { ReviewsTabModel } from '../../pages/reviews/reviewsTabModel';

interface JsonLd {
  '@context': string;
  '@type': string;
  name: string;
  aggregateRating?: {
    '@type': string;
    ratingCount: number;
    ratingValue: number;
  };
  url?: string;
  logo?: string;
  reviews?: ReviewJsonLd[];
}

interface ReviewJsonLd {
  '@type': string;
  author?: {
    '@type': string;
    jobTitle?: string;
  };
  reviewBody?: string;
  datePublished?: string;
  reviewRating?: {
    '@type': string;
    ratingValue?: number;
  };
}

export const mapToReviewJsonLd = (review: ReviewRowModel) => {
  const formattedDate = review.createdAt.split('T')[0];
  return {
    '@type': 'Review',
    author: {
      '@type': 'Person',
      jobTitle: review.jobTitle,
    },
    reviewBody: review.title,
    datePublished: formattedDate,
    reviewRating: {
      '@type': 'Rating',
      ratingValue: review.overallRating,
    },
  };
};

export const mapToJsonLd = (data: SeoStructuredData): JsonLd => ({
  '@context': 'http://schema.org',
  '@type': 'LocalBusiness',
  name: data.name,
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingCount: data.ratingCount,
    ratingValue: data.ratingValue,
  },
  ...(data?.location ? { address: data?.location } : {}),
  ...(data?.url ? { url: data?.url } : {}),
  ...(data?.logo ? { logo: data?.logo } : {}),
  ...(data?.reviews
    ? { review: data?.reviews.map((review) => mapToReviewJsonLd(review)) }
    : {}),
});

export const mapToSeoData = (
  data: AboutModel | ReviewsTabModel,
): SeoStructuredData => ({
  name: data.name,
  url: data.overview?.website?.url,
  logo: data.branding?.logoImageUrl,
  ratingCount: data.reviewSummary?.overallRating?.count ?? 0,
  ratingValue: data.reviewSummary?.overallRating?.average ?? 0,
  location: data.overview?.primaryLocation,
  ...('reviews' in data
    ? { reviews: (data as ReviewsTabModel).reviews?.reviews }
    : {}),
});
