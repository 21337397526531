// istanbull-ignore-file
// this is a mock file for testing
/* eslint-disable @typescript-eslint/no-empty-function */
import { creatRestClient } from '@seek/libs-shared';
import type {
  GetCompaniesResult,
  GetCompanyResult,
  GetCompanyReviewsJobTitlesResult,
  LegacyCompanyProfile,
  LegacyCompanyReviewsSummaryResult,
  LegacySearchCompaniesResult,
} from './types';

export const createLegacyCompanyProfileServiceMock = () => {
  const getCompanies = async (options: {
    page?: number;
    per_page?: number;
    company_name?: string;
    foreign_company_id?: string;
    sort?: string;
    advertiser_id?: string;
  }): Promise<GetCompaniesResult> => ({
    data: [
      {
        address: null,
        avg_move_duration: null,
        ceo: null,
        clean_url: '813341-see-civil',
        company_id: '813341',
        company_name: 'SEE Civil',
        company_name_slug: 'see-civil-813341',
        company_no: null,
        company_logo_url:
          'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813341/logo/db370df0-cd44-11ea-bfc5-69c296d2dc49.jpeg',
        company_review_summary: {
          reviews_count: 21,
          average_company_rating: 3.1905,
        },
        company_review_summary_url: '/companies/813341/company-reviews/summary',
        company_review_url: '/companies/813341/company-reviews',
        company_snapshot: null,
        foreign_company_id: '18114',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          "We have grown to be a leader in our field because we build and nurture client relationships, we rise to any challenge and we value innovation and resourcefulness. We are committed to delivering a superior quality product in the shortest possible time frame maximising value for our clients\n\nSource: This is an extract from the company's own website.",
        map_address: null,
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/813341/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: null,
        website_url: 'http://seecivil.com.au/about-us/',
        verified_company_no: null,
        year_founded: null,
        is_parent: false,
        related_companies_info: [],
      },
      {
        address: 'RAILWAY ST, 55\n3666\nEUROA\nAustralia',
        avg_move_duration: null,
        ceo: null,
        clean_url: '438910-seed-force',
        company_id: '438910',
        company_name: 'Seed Force',
        company_name_slug: 'seed-force-438910',
        company_no: null,
        company_logo_url:
          'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/438910/logo/6482c990-bcca-11ea-86d1-e52bae5cc086.jpeg',
        company_review_summary: {
          reviews_count: 0,
          average_company_rating: 0,
        },
        company_review_summary_url: '/companies/438910/company-reviews/summary',
        company_review_url: '/companies/438910/company-reviews',
        company_snapshot: null,
        foreign_company_id: '156',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          "Seed Force was set up in Australia in 2006 to provide a source of world class forage genetics, 100% researched in the local environment. The company has a dominant Australasian shareholding with considerable experience in the seed industry. Over the past five years we have screened over 8,000 plots covering 19 species at multiple sites in all six Australian states to find new forage options that can deliver improved performance and profitability over existing commercial varieties. \nDelivering greater value from seed\n\n\n\n\nSeed Force has spent considerable time screening and measuring yield, quality and water use efficiency of various forage options and cultivars with the aim of marketing options that can lift the bar in terms of production efficiency compared to current commercial cultivars. And our research into management practices such as sowing rate, sowing time, fertiliser response and grazing guidelines continues to deliver better information to create greater value at the farm gate for livestock production.\nSource: This is an extract from the company's own website",
        map_address: 'RAILWAY ST, 55\n3666\nEUROA\nAustralia',
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/438910/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: null,
        website_url: 'seedforce.com.au',
        verified_company_no: null,
        year_founded: 2006,
        is_parent: false,
        related_companies_info: [],
      },
      {
        address: 'Level 5, 425 St Kilda Road, Victoria',
        avg_move_duration: null,
        ceo: null,
        clean_url: '434802-seed-heritage',
        company_id: '434802',
        company_name: 'Seed Heritage',
        company_name_slug: 'seed-heritage-434802',
        company_no: null,
        company_logo_url:
          'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/434802/logo/69929050-bcca-11ea-86d1-e52bae5cc086.png',
        company_review_summary: {
          reviews_count: 104,
          average_company_rating: 2.6635,
        },
        company_review_summary_url: '/companies/434802/company-reviews/summary',
        company_review_url: '/companies/434802/company-reviews',
        company_snapshot: null,
        foreign_company_id: '1414',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          "Our story begins in 2000 in a small shop front in Armadale, Victoria, where the founder of seed was inspired by the need for a one of a kind\nand compelling shopping experience that made children and their parents feel connected through great design. 14 years later, Seed is one of the most widely recognised Australian retailers of modern fashion and accessories.\n\nWith apparel, jewellery and footwear added to the treasury of product, Seed remains a bastion of style and substance that effortlessly withstands the test of time. Monthly collections are comprised of high quality and beautifully designed product for newborns to children aged 10, a teen collection for girls and boys aged 8-16 years and for women of every age.\n\nSource: this is an extract from the company's own website",
        map_address: 'Level 5, 425 St Kilda Road, Victoria',
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/434802/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [
          {
            social_profile_key: 'twitter',
            url: 'http://twitter.com/seedheritage',
          },
          {
            social_profile_key: 'facebook',
            url: 'http://www.facebook.com/seedheritage',
          },
        ],
        stock_ticker: null,
        website_url: 'http://seedheritage.com/',
        verified_company_no: null,
        year_founded: 1961,
        is_parent: false,
        related_companies_info: [],
      },
      {
        address: '60 Cremorne St, Cremorne, VIC 3121',
        avg_move_duration: null,
        ceo: null,
        clean_url: '432600-seek',
        company_id: '432600',
        company_name: 'SEEK',
        company_name_slug: 'seek-432600',
        company_no: null,
        company_image_urls: null,
        company_logo_url:
          'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        company_review_summary: {
          reviews_count: 183,
          average_company_rating: 4.6339,
        },
        company_review_summary_url: '/companies/432600/company-reviews/summary',
        company_review_url: '/companies/432600/company-reviews',
        company_size: '1,001-5,000',
        company_snapshot: null,
        exact_company_size: 4374,
        foreign_company_id: '3987',
        industry: 'Advertising, Arts & Media',
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          '<p>SEEK is a diverse group of companies that have a unified purpose to help people live more fulfilling and productive working lives and help organisations succeed. </p>\n\n<p>SEEK is listed on the Australian Securities Exchange where it is a top 100 company employing over 6,000 people and with a market capitalisation close to AU $6billion. </p>\n\n<p>SEEK is the global leader in the creation and operation of online employment markets. SEEK makes a positive contribution to people’s lives through connecting more people to relevant job opportunities using its marketplace scale and technology to build radically more efficient and effective employment marketplaces.</p>\n\n<p>SEEK owns leading job boards in Australia, New Zealand, China, Brazil, Mexico, Africa and across South East Asia and has exposure to 4 billion people and relationships with over 700,000 hirers and 150 million candidates. SEEK has the largest market capitalisation of any job board in the world.</p>',
        map_address: '60 Cremorne St, Cremorne, VIC 3121',
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/432600/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: 'SEK',
        tags: [
          'Amazon Associates Program',
          'Opportunities',
          'Partners Programs',
        ],
        website_url: 'https://seek.com.au',
        verified_company_no: null,
        year_founded: 1997,
        is_parent: false,
        related_companies_info: [],
      },
      {
        address: null,
        avg_move_duration: null,
        ceo: null,
        clean_url: '815091-seeley-international',
        company_id: '815091',
        company_name: 'Seeley International',
        company_name_slug: 'seeley-international-815091',
        company_no: null,
        company_logo_url:
          'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/815091/logo/659e4bb0-bcca-11ea-86d1-e52bae5cc086.png',
        company_review_summary: {
          reviews_count: 17,
          average_company_rating: 3.2353,
        },
        company_review_summary_url: '/companies/815091/company-reviews/summary',
        company_review_url: '/companies/815091/company-reviews',
        company_snapshot: null,
        foreign_company_id: '19909',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          "Seeley International is Australia's largest air conditioning manufacturer and a global leader in developing ingenious, energy-efficient cooling and heating products.\n\nOur vision is to lead the world in creating climate control solutions which continue to be highly innovative, of premium quality and inspirational in their delivery of energy-efficiency.\n\n \n\nBut it’s more than just a vision... it’s a way of life!\n\nA commitment to innovation and excellence is at the heart of all that we do. Our success in delivering on that commitment has been recognised by our many awards and our expanding global presence… Seeley International now exports to more than 100 countries. Not bad for a company that started out in 1972 in the garage of its founder and Chairman, Frank Seeley AM!\n\nSource: This is an extract from the company's own website.",
        map_address: null,
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/815091/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: null,
        website_url: 'https://www.seeleyinternational.com',
        verified_company_no: null,
        year_founded: null,
        is_parent: false,
        related_companies_info: [],
      },
      {
        address: null,
        avg_move_duration: null,
        ceo: null,
        clean_url: '861102-seeing-machines',
        company_id: '861102',
        company_name: 'Seeing Machines',
        company_name_slug: 'seeing-machines-861102',
        company_no: null,
        company_image_urls: null,
        company_logo_url:
          'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/861102/logo/d3883460-becf-11ed-89b1-51c93452decb.png?1678403533581',
        company_review_summary: {
          reviews_count: 5,
          average_company_rating: 2.8,
        },
        company_review_summary_url: '/companies/861102/company-reviews/summary',
        company_review_url: '/companies/861102/company-reviews',
        company_snapshot: null,
        foreign_company_id: '22651',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          'Today and into the future, humans will always be at the core of what we do – not just the operator of a vehicle but also the passengers that they have onboard and the communities they serve.  \nSeeing Machines, (AIM: SEE) has over 15 years of research know-how, commercial application and proven expertise in intelligent face and eye tracking technology.\n\nHeadquartered in Australia and with operations in North America, Europe, Latin America, Middle East and Asia Pacific, the company pioneers the development and commercialisation of proprietary algorithms and hardware that help machines interpret the human face and eyes in order to understand their state. This Core IP brings to life a range of technology applications that make devices and vehicles smarter, with the intelligence and ability to protect, enable and respond when intervention is required.\n\nSeeing Machines has strategic relationships with world leaders like Caterpillar, Electro-Motive Diesel, Boeing, Takata, Bosch, LG and Panasonic among others.\nSource: this is an extract from the company’s own website.',
        map_address: null,
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/861102/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: null,
        tags: null,
        website_url: 'https://seeingmachines.com/',
        verified_company_no: null,
        year_founded: null,
        is_parent: false,
        related_companies_info: [],
      },
      {
        address: 'Gate 2A Hartley Grove Urrbrae SA 5064',
        avg_move_duration: null,
        ceo: null,
        clean_url: '873336-seed-services-australia',
        company_id: '873336',
        company_name: 'Seed Services Australia',
        company_name_slug: 'seed-services-australia-873336',
        company_no: null,
        company_logo_url:
          'https://company-profile-images.s3.amazonaws.com/images/873336/1201790/logo/seed-services-australia-logo.jpg',
        company_review_summary: {
          reviews_count: 0,
          average_company_rating: 0,
        },
        company_review_summary_url: '/companies/873336/company-reviews/summary',
        company_review_url: '/companies/873336/company-reviews',
        company_snapshot: null,
        foreign_company_id: '47235',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          "Seed Services Australia offers seed testing, seed certification and quality assurance programs to seed growers, companies, processors, resellers, exporters and farmers.\n\nSource: This is an extract from the company's own website.",
        map_address: 'Gate 2A Hartley Grove Urrbrae SA 5064',
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/873336/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: null,
        website_url: 'http://www.pir.sa.gov.au/',
        verified_company_no: null,
        year_founded: null,
        is_parent: false,
        hierarchy_type: 'separated',
        parent_company_info: {
          company_id: '795922',
          foreign_company_id: '7860',
          company_name: 'Rural Solutions SA',
          is_profile_public: true,
          company_logo_url:
            'https://company-profile-images.s3.amazonaws.com/images/795922/1050528/logo/rural-solutions-sa-logo.jpg',
          clean_url: '795922-rural-solutions',
          company_review_summary: {
            average_company_rating: 0,
            reviews_count: 0,
          },
        },
        related_companies_info: [],
      },
      {
        address: '1/336 South Rd, Richmond SA 5033',
        avg_move_duration: null,
        ceo: null,
        clean_url: '438989-seek-technology',
        company_id: '438989',
        company_name: 'Seek Technology',
        company_name_slug: 'seek-technology-438989',
        company_no: null,
        company_logo_url:
          'https://company-profile-images.s3.amazonaws.com/images/438989/995000/logo/seek-technology-logo.png',
        company_review_summary: {
          reviews_count: 0,
          average_company_rating: 0,
        },
        company_review_summary_url: '/companies/438989/company-reviews/summary',
        company_review_url: '/companies/438989/company-reviews',
        company_snapshot: null,
        foreign_company_id: '3823',
        industry: null,
        is_profile_public: true,
        join_us_descr: null,
        long_description:
          'Seek Tech (formerly Seek Technology) was founded in 2005 by David Marshall and David Stuart.  With a passion for technology &amp; business, as our name suggests, we continually seek the latest in technology solutions to improve how business provide services and products to their respective clients. Individually, David Stuart and I have earned over 15 years experience providing IT consulting and support to small and medium sized business, ranging from sole traders through to national and international companies.\n\nThroughout the past 9 years, Seek Tech have grown as a company both with our services, solutions and our team. We practice what we preach, we love technology, and we live to help people with theirs.\n\nSeek Tech build strong, longstanding business relationships by always placing our customers interest first, being honest and open communicators, taking ownership of any problem and proving to be extremely reliable.\nSource: this is an extract from the company’s own website',
        map_address: '1/336 South Rd, Richmond SA 5033',
        map_latitude: null,
        map_longitude: null,
        secondary_foreign_company_ids: [],
        similar_company_url: '/companies/438989/similar',
        short_description: null,
        should_display_profile: true,
        should_display_on_jobs: true,
        should_display_similar_companies: true,
        social_profiles: [],
        stock_ticker: null,
        website_url: 'www.seektech.com.au',
        verified_company_no: null,
        year_founded: 2005,
        is_parent: false,
        related_companies_info: [],
      },
    ],
    paging: {
      page: options.page || 1,
      per_page: options.per_page || 10,
      total: 0,
    },
  });
  const getCompany = async (_companyId: string): Promise<GetCompanyResult> => ({
    data: {
      address: 'address',
      avg_move_duration: null,
      ceo: null,
      clean_url: 'clean_url',
      company_id: 'company_id',
      company_name: 'company_name',
      company_name_slug: 'company_name_slug',
      company_no: null,
      company_review_summary: {
        reviews_count: 0,
        average_company_rating: 0,
      },
      company_review_summary_url: 'company_review_summary_url',
      company_review_url: 'company_review_url',
      company_snapshot: null,
      foreign_company_id: 'foreign_company_id',
      industry: null,
      is_profile_public: false,
      join_us_descr: null,
      long_description: 'long_description',
      map_address: 'map_address',
      map_latitude: null,
      map_longitude: null,
      secondary_foreign_company_ids: [],
      similar_company_url: 'similar_company_url',
      short_description: null,
      social_profiles: [],
      stock_ticker: 'asx',
      tags: [],
      website_url: 'website_url',
      verified_company_no: null,
      year_founded: 2000,
      is_parent: false,
      related_companies_info: [],
      exact_company_size: 666,
      company_logo_url: 'company_logo_url',
      company_size: '100+',
    },
  });

  const getLegacyCompanyProfile = async (
    _companyId: string,
  ): Promise<LegacyCompanyProfile> => ({
    address: '60 Cremorne St, Cremorne, VIC 3121',
    assets: {
      cover: {
        strategies: {
          jdp_cover:
            'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f/42bf52e76925e2211c3d956332bf254f2157d3de',
        },
        id: '73a9ac271ce110bfdc91562ba0619b37f4630a5f',
        url: 'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f',
      },
      logo: {
        strategies: {
          jdp_logo:
            'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        },
        id: '065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
        url: 'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
      },
    },
    company_id: '432600',
    company_name: 'SEEK',
    culture_values: {
      heading: 'Our SEEK',
      values: [
        {
          text: 'We are passionate about our purpose, our customers and our community.',
          title: 'PASSION',
        },
        {
          text: 'We care about each other and collaborate to achieve together.',
          title: 'TEAM',
        },
        {
          text: 'We execute with excellence and achieve great results.',
          title: 'DELIVERY',
        },
        {
          text: 'We think and act for the long-term.',
          title: 'FUTURE',
        },
      ],
      photo:
        'https://image-service-cdn.seek.com.au/173a452b8e53d9edf217588aaa1c2feee8db9906',
      text: 'Our SEEK is a statement that describes our culture, and what we know to be true for success at SEEK. By using Our SEEK everyday, we deliver on our Purpose, create great value for our customers, and make SEEK an even better place to work.',
    },
    custom_statement: {
      text: [
        'At SEEK we are energised about making our communities even better. In keeping with our purpose of helping people live more fulfilling and productive working lives and to help organisations succeed, we are committed to operating sustainably and to always think and act with long-term goals in mind.',
        'SEEK approaches sustainability via an Environment, Social and Governance (ESG) Framework, with a focus on priorities that deliver social and environmental value for our communities and our people. We support the United Nations Sustainable Development Goals as a blueprint to achieve a better and more sustainable future for all.',
        'For more information see https://www.seek.com.au/about/sustainability/',
      ],
      title: 'Our approach to sustainability',
    },
    featured: {
      sub_heading: 'Best places 2022 awards',
      link: 'https://image-service-cdn.seek.com.au/5a283cb51a07b72a18bd648d0fbdd2e12090bb61',
      text: [
        "Our people are what make SEEK the company it is today, and we're honoured to be recognised as a top 10 Best Places to Work in Tech 2022 by The Australian Financial Review. In 2021, SEEK was named the Best Place to Work Overall and the #1 Best Place to Work in Tech.",
      ],
      link_is_video: false,
    },
    foreign_company_id: '3987',
    gallery: {
      photo_video_gallery: {
        photos: [
          {
            date: 1657861531,
            link: 'https://image-service-cdn.seek.com.au/8050efd72ed53e3c231764909ef04bd5d84e782f',
            title: 'Our new office in Cremorne',
          },
          {
            date: 1657861507,
            link: 'https://image-service-cdn.seek.com.au/9707bf991663ffb09c611fe16f1e3cc142796f5d',
            title: 'SEEK HQ',
          },
          {
            date: 1657861497,
            link: 'https://image-service-cdn.seek.com.au/f725757bfaadb123933eee461a3b7537c7be16f0',
            title: 'SEEK HQ',
          },
          {
            date: 1657861484,
            link: 'https://image-service-cdn.seek.com.au/c5a941b4a9bab2dc94cb1c1189dde2210f5d02af',
            title: 'SEEK HQ',
          },
          {
            date: 1657861458,
            link: 'https://image-service-cdn.seek.com.au/ed212d5e4dcb2d5a3ae8ef137677d688c280f031',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657861445,
            link: 'https://image-service-cdn.seek.com.au/10620888e059667cfa2902357d3f17d60c75ced0',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657860643,
            link: 'https://image-service-cdn.seek.com.au/88136ec8aa79c555211ee720ed7c9cd3babe824f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860630,
            link: 'https://image-service-cdn.seek.com.au/816aa8e1f313927b0b92cce65a6bde5a50560d1f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860617,
            link: 'https://image-service-cdn.seek.com.au/8e23642ca5822decdc08d591d7c694ed36fb5c4d',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860600,
            link: 'https://image-service-cdn.seek.com.au/1392686cc727fab65ffc42cd3dc526b3a70f15dd',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860588,
            link: 'https://image-service-cdn.seek.com.au/b2bbd878dbede8712dae31e91434c90c07b7fb97',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860575,
            link: 'https://image-service-cdn.seek.com.au/028a8d7f7c1dcaf6aeef8c26bb6f340362965e8e',
            title: 'Hackathon 2022',
          },
          {
            date: 1641365894,
            link: 'https://image-service-cdn.seek.com.au/39d3238cda46d494c5ff6a0bb3a4fc948b9032df',
            title: 'Celebrations at SEEK!',
          },
          {
            date: 1641365871,
            link: 'https://image-service-cdn.seek.com.au/6dd0f6686bdf37f882c34b468804c8354221e1db',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365864,
            link: 'https://image-service-cdn.seek.com.au/0f4d29c5bf5b151c16f2b9e201e197c44ba01621',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365856,
            link: 'https://image-service-cdn.seek.com.au/f87b48e81a81ec51ce94c44d265ece5b0d26352e',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365847,
            link: 'https://image-service-cdn.seek.com.au/6d9e37276d63066d08dba63a8da0e184cd50e858',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365829,
            link: 'https://image-service-cdn.seek.com.au/df5b83687d4e01fd550d46d7dda3c5ae7cf740d2',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365771,
            link: 'https://image-service-cdn.seek.com.au/b8877331ddfa7a6dbb0c26924a53daaf6867ae6c',
            title: 'Hackathon 2019',
          },
        ],
      },
    },
    industry: 'Advertising, Arts & Media',
    mission_statement: [
      'Our purpose is to help people live more fulfilling and productive working lives and help organisations succeed.',
    ],
    number_of_employees_range: '1,001-5,000',
    overview: [
      'SEEK is a global leader in employment marketplaces, solving real problems for our customers and connecting the right work with the right people. Our platforms are used by approximately 50 million candidates worldwide, we have relationships with over 300,000 hirers and exposure to around 900 million people, which means we really know the markets we are operating in.',
      'With an international presence that spans Australia, New Zealand, South-East Asia, Brazil and Mexico, your career opportunities are many at SEEK.',
    ],
    perks_benefits: [
      {
        text: 'Free kick-start breakfast every morning and free coffee, tea and fresh fruit are available all day in our offices.',
        title: 'Free Breakfast',
      },
      {
        text: '14 weeks paid primary carer leave with parental coaching program and support to transition back to work.',
        title: 'Support for parents',
      },
      {
        text: 'Paid volunteer opportunities with our charity partners, and salary deductions direct to our Small Change workplace giving program.',
        title: 'Community Impact',
      },
      {
        text: 'A range of internal and external opportunities to support SEEKers to reach their full potential.',
        title: 'Learning & Development:',
      },
    ],
    should_display_profile: true,
    specialities: ['Online Employment Classifieds', 'Job Boards', 'Education'],
    website_url: 'https://seek.com.au',
    awards: [
      {
        year: 2022,
        name: 'Best Places to work',
        link: 'https://image-service-cdn.seek.com.au/d396661325772a167de0cd248d7fc4ecdc3c0403',
      },
      {
        year: 2022,
        name: 'Certified carbon neutral for ANZ, Asia & Latin America business operations FY',
        link: 'https://image-service-cdn.seek.com.au/e54df8f98facd86b4e5d76154188b9f4bdf98349',
      },
    ],
    logo_url:
      'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
  });

  const getLegacyCompanyProfilePreview = async (
    _companyId: string,
  ): Promise<LegacyCompanyProfile> => ({
    address: '60 Draft Cremorne St, Cremorne, VIC 3121',
    assets: {
      cover: {
        strategies: {
          jdp_cover:
            'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f/42bf52e76925e2211c3d956332bf254f2157d3de',
        },
        id: '73a9ac271ce110bfdc91562ba0619b37f4630a5f',
        url: 'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f',
      },
      logo: {
        strategies: {
          jdp_logo:
            'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        },
        id: '065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
        url: 'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
      },
    },
    company_id: '432600',
    company_name: 'SEEK draft',
    culture_values: {
      heading: 'Our SEEK draft',
      values: [
        {
          text: 'We are passionate about our purpose, our customers and our community.',
          title: 'PASSION',
        },
        {
          text: 'We care about each other and collaborate to achieve together.',
          title: 'TEAM',
        },
        {
          text: 'We execute with excellence and achieve great results.',
          title: 'DELIVERY',
        },
        {
          text: 'We think and act for the long-term.',
          title: 'FUTURE',
        },
      ],
      photo:
        'https://image-service-cdn.seek.com.au/173a452b8e53d9edf217588aaa1c2feee8db9906',
      text: 'Our SEEK is a statement that describes our culture, and what we know to be true for success at SEEK. By using Our SEEK everyday, we deliver on our Purpose, create great value for our customers, and make SEEK an even better place to work.',
    },
    custom_statement: {
      text: [
        'At SEEK draft we are energised about making our communities even better. In keeping with our purpose of helping people live more fulfilling and productive working lives and to help organisations succeed, we are committed to operating sustainably and to always think and act with long-term goals in mind.',
        'SEEK approaches sustainability via an Environment, Social and Governance (ESG) Framework, with a focus on priorities that deliver social and environmental value for our communities and our people. We support the United Nations Sustainable Development Goals as a blueprint to achieve a better and more sustainable future for all.',
        'For more information see https://www.seek.com.au/about/sustainability/',
      ],
      title: 'Our approach to sustainability',
    },
    featured: {
      sub_heading: 'Best places 2022 awards',
      link: 'https://image-service-cdn.seek.com.au/5a283cb51a07b72a18bd648d0fbdd2e12090bb61',
      text: [
        "Our people are what make SEEK the company it is today, and we're honoured to be recognised as a top 10 Best Places to Work in Tech 2022 by The Australian Financial Review. In 2021, SEEK was named the Best Place to Work Overall and the #1 Best Place to Work in Tech.",
      ],
      link_is_video: false,
    },
    foreign_company_id: '3987',
    gallery: {
      photo_video_gallery: {
        photos: [
          {
            date: 1657861531,
            link: 'https://image-service-cdn.seek.com.au/8050efd72ed53e3c231764909ef04bd5d84e782f',
            title: 'Our new office in Cremorne',
          },
          {
            date: 1657861507,
            link: 'https://image-service-cdn.seek.com.au/9707bf991663ffb09c611fe16f1e3cc142796f5d',
            title: 'SEEK HQ',
          },
          {
            date: 1657861497,
            link: 'https://image-service-cdn.seek.com.au/f725757bfaadb123933eee461a3b7537c7be16f0',
            title: 'SEEK HQ',
          },
          {
            date: 1657861484,
            link: 'https://image-service-cdn.seek.com.au/c5a941b4a9bab2dc94cb1c1189dde2210f5d02af',
            title: 'SEEK HQ',
          },
          {
            date: 1657861458,
            link: 'https://image-service-cdn.seek.com.au/ed212d5e4dcb2d5a3ae8ef137677d688c280f031',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657861445,
            link: 'https://image-service-cdn.seek.com.au/10620888e059667cfa2902357d3f17d60c75ced0',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657860643,
            link: 'https://image-service-cdn.seek.com.au/88136ec8aa79c555211ee720ed7c9cd3babe824f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860630,
            link: 'https://image-service-cdn.seek.com.au/816aa8e1f313927b0b92cce65a6bde5a50560d1f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860617,
            link: 'https://image-service-cdn.seek.com.au/8e23642ca5822decdc08d591d7c694ed36fb5c4d',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860600,
            link: 'https://image-service-cdn.seek.com.au/1392686cc727fab65ffc42cd3dc526b3a70f15dd',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860588,
            link: 'https://image-service-cdn.seek.com.au/b2bbd878dbede8712dae31e91434c90c07b7fb97',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860575,
            link: 'https://image-service-cdn.seek.com.au/028a8d7f7c1dcaf6aeef8c26bb6f340362965e8e',
            title: 'Hackathon 2022',
          },
          {
            date: 1641365894,
            link: 'https://image-service-cdn.seek.com.au/39d3238cda46d494c5ff6a0bb3a4fc948b9032df',
            title: 'Celebrations at SEEK!',
          },
          {
            date: 1641365871,
            link: 'https://image-service-cdn.seek.com.au/6dd0f6686bdf37f882c34b468804c8354221e1db',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365864,
            link: 'https://image-service-cdn.seek.com.au/0f4d29c5bf5b151c16f2b9e201e197c44ba01621',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365856,
            link: 'https://image-service-cdn.seek.com.au/f87b48e81a81ec51ce94c44d265ece5b0d26352e',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365847,
            link: 'https://image-service-cdn.seek.com.au/6d9e37276d63066d08dba63a8da0e184cd50e858',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365829,
            link: 'https://image-service-cdn.seek.com.au/df5b83687d4e01fd550d46d7dda3c5ae7cf740d2',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365771,
            link: 'https://image-service-cdn.seek.com.au/b8877331ddfa7a6dbb0c26924a53daaf6867ae6c',
            title: 'Hackathon 2019',
          },
        ],
      },
    },
    industry: 'Advertising, Arts & Media',
    mission_statement: [
      'Our draft purpose is to help people live more fulfilling and productive working lives and help organisations succeed.',
    ],
    number_of_employees_range: '1,001-5,000',
    overview: [
      'SEEK is a global leader in employment marketplaces, solving real problems for our customers and connecting the right work with the right people. Our platforms are used by approximately 50 million candidates worldwide, we have relationships with over 300,000 hirers and exposure to around 900 million people, which means we really know the markets we are operating in.',
      'With an international presence that spans Australia, New Zealand, South-East Asia, Brazil and Mexico, your career opportunities are many at SEEK.',
    ],
    perks_benefits: [
      {
        text: 'draft Free kick-start breakfast every morning and free coffee, tea and fresh fruit are available all day in our offices.',
        title: 'Free Breakfast',
      },
      {
        text: '14 weeks paid primary carer leave with parental coaching program and support to transition back to work.',
        title: 'Support for parents',
      },
      {
        text: 'Paid volunteer opportunities with our charity partners, and salary deductions direct to our Small Change workplace giving program.',
        title: 'Community Impact',
      },
      {
        text: 'A range of internal and external opportunities to support SEEKers to reach their full potential.',
        title: 'Learning & Development:',
      },
    ],
    should_display_profile: true,
    specialities: ['Online Employment Classifieds', 'Job Boards', 'Education'],
    website_url: 'https://seek.com.au',
    awards: [
      {
        year: 2022,
        name: 'Best Places to work',
        link: 'https://image-service-cdn.seek.com.au/d396661325772a167de0cd248d7fc4ecdc3c0403',
      },
      {
        year: 2022,
        name: 'Certified carbon neutral for ANZ, Asia & Latin America business operations FY',
        link: 'https://image-service-cdn.seek.com.au/e54df8f98facd86b4e5d76154188b9f4bdf98349',
      },
    ],
    logo_url:
      'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
  });

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string> => `99${companyId}`;

  const getCompanyReviews = async (
    _companyId: string,
    _options: {
      page: number;
      sort?: string;
    },
  ) => {
    const response = {
      data: [
        {
          company_id: '1217901',
          job_title: 'test job title',
          review_title: 'test',
          pros: 'test pro',
          cons: 'test con',
          created_at: '2017-04-07T03:05:42.000Z',
          rating: {
            work_life_balance: 3,
            benefits_and_perks: 3,
            company_overall: 4,
            diversity: 3,
            work_environment: 3,
            executive_management: 3,
            career_opportunity: 3,
          },
          review_id: '1294474',
          upvotes: {
            user_ids: [],
            count: 18,
            last_upvote_at: '2023-01-12T02:39:38.425Z',
          },
          replies: [
            {
              created_at: '2022-12-08T06:23:38.726Z',
              from: 'Manager',
              text: 'My test',
              user_id: '1',
            },
          ],
          anonymize_job_title: false,
          company_name: 'SEEK',
          id: '1294474',
          is_anonymized: false,
          rating_benefits_and_perks: 3,
          rating_career_opportunity: 3,
          rating_company_overall: 4,
          rating_executive_management: 3,
          rating_work_environment: 3,
          rating_work_life_balance: 3,
          rating_diversity: 3,
          title: 'test',
          total_upvote: 18,
          upvoted_users: [],
          review_response: 'My test',
          review_response_by: 'Manager',
          review_response_foreign_user_id: '1',
          review_response_created_at: '2022-12-08T06:23:38.726Z',
          review_url: '/companies/1217901/company-reviews/1294474',
          review_created_at: '2017-04-07T03:05:42.000Z',
          company_recommended: true,
          salary_summary: 'below',
        },
        {
          company_id: '1217901',
          job_title: 'test job title',
          review_title: 'test',
          pros: 'test pro',
          cons: 'test con',
          created_at: '2017-04-07T03:05:21.000Z',
          rating: {
            work_life_balance: 3,
            benefits_and_perks: 3,
            company_overall: 2,
            diversity: 3,
            work_environment: 3,
            executive_management: 3,
            career_opportunity: 3,
          },
          review_id: '1294472',
          upvotes: {
            user_ids: [],
            count: 16,
            last_upvote_at: '2023-01-12T02:39:52.723Z',
          },
          replies: [
            {
              user_id: '1',
              created_at: '2022-12-09T00:38:03.318Z',
              from: 'Manager',
              text: 'Some reply',
            },
          ],
          anonymize_job_title: false,
          company_name: 'SEEK',
          id: '1294472',
          is_anonymized: false,
          rating_benefits_and_perks: 3,
          rating_career_opportunity: 3,
          rating_company_overall: 2,
          rating_executive_management: 3,
          rating_work_environment: 3,
          rating_work_life_balance: 3,
          rating_diversity: 3,
          title: 'test',
          total_upvote: 16,
          upvoted_users: [],
          review_response: 'Some reply',
          review_response_by: 'Manager',
          review_response_foreign_user_id: '1',
          review_response_created_at: '2022-12-09T00:38:03.318Z',
          review_url: '/companies/1217901/company-reviews/1294472',
          review_created_at: '2017-04-07T03:05:21.000Z',
          company_recommended: true,
          salary_summary: 'fair',
        },
      ],
      paging: {
        page: 1,
        per_page: 10,
        total: 2,
        unfiltered_total: 2,
      },
    };

    return response;
  };

  const getCompanyReviewsSummary = async (
    companyId: string,
  ): Promise<LegacyCompanyReviewsSummaryResult> => ({
    data: {
      company_id: companyId,
      company_name: 'SEEK',
      company_review_summary: {
        approved_reviews_count: 100,
        average_benefits_and_perks: 4.5,
        average_benefits_and_perks_percentage: 90,
        average_career_opportunity: 4.5,
        average_career_opportunity_percentage: 90,
        average_company_rating: 4.5,
        average_company_rating_percentage: 90,
        average_rating_diversity: 4.5,
        average_rating_diversity_percentage: 90,
        average_executive_management: 4.5,
        average_executive_management_percentage: 90,
        average_stress_level: 4.5,
        average_rating_stress_level_scale_1_5: 4.5,
        average_rating_stress_level_scale_1_5_percentage: 90,
        average_work_life_balance: 4.5,
        average_work_life_balance_percentage: 90,
        average_work_environment: 4.5,
        average_work_environment_percentage: 90,
        company_rating_1_star_count: 100,
        company_rating_1_star_percentage: 20,
        company_rating_2_star_count: 100,
        company_rating_2_star_percentage: 20,
        company_rating_3_star_count: 100,
        company_rating_3_star_percentage: 20,
        company_rating_4_star_count: 100,
        company_rating_4_star_percentage: 20,
        company_rating_5_star_count: 100,
        company_rating_5_star_percentage: 20,
        company_recommended_percentage: 90,
        paid_on_right_day_percentage: 90,
        reviews_count: 100,
        salary_fair_count: 100,
        salary_fair_percentage: 90,
        salary_generous_count: 100,
        salary_generous_percentage: 90,
        salary_poor_count: 100,
        salary_poor_percentage: 90,
      },
    },
  });

  const searchCompanies = async ({
    searchTerm,
    _page,
  }: {
    searchTerm: string;
    _page?: number;
  }): Promise<LegacySearchCompaniesResult> => ({
    data: [
      {
        company_name_slug: searchTerm,
        company_name: searchTerm,
        average_company_rating: 3.3,
        logo_url: '',
        reviews_count: 111,
      },
      {
        company_name_slug: 'seek',
        company_name: 'Seek',
        average_company_rating: 4.5,
        logo_url: '',
        reviews_count: 321,
      },
    ],
    paging: { page: 1, per_page: 10, total: 333 },
  });

  const getCompanyReviewsJobTitles = async (
    companyId: string,
  ): Promise<GetCompanyReviewsJobTitlesResult> => ({
    company_id: companyId,
    job_titles: [
      { name: 'Software Engineer', reviews_count: 9 },
      { name: 'Software Developer', reviews_count: 9 },
      { name: 'Software Engineer Intern', reviews_count: 9 },
      { name: 'Software Engineer Graduate', reviews_count: 9 },
    ],
  });

  const { get } = creatRestClient(
    'https://api-seek.staging.companyreview.co',
    {
      info: () => {},
      error: () => {},
      warn: () => {},
      trace: () => {},
      debug: () => {},
      fatal: () => {},
    },
    {
      // ...commonHeaders,

      'x-country-iso': 'au',
    },
  );
  const getReviewsFeed = async ({
    companyId,
    country,
  }: {
    companyId: number;
    country: string;
  }) =>
    await get(`/companies/${companyId}/reviews/feed`, {
      headers: {
        'x-country-iso': country,
      },
    });

  return {
    getCompanies,
    getCompany,
    getCompanyReviews,
    getCompanyProfileForeignId,
    getCompanyReviewsJobTitles,
    getCompanyReviewsSummary,
    getLegacyCompanyProfile,
    getLegacyCompanyProfilePreview,
    getReviewsFeed,
    searchCompanies,
  };
};
