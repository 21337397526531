export const convertToRecord = (
  flagArray: { key: string; value: unknown }[],
): Record<string, unknown> => {
  return flagArray?.reduce(
    (acc: Record<string, unknown>, flag: { key: string; value: unknown }) => {
      acc[flag.key] = flag.value;
      return acc;
    },
    {},
  );
};